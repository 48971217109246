<template>
    <PageContent>
        <PageTitle>New User</PageTitle>
        <NotReady v-if="!ready" />
        <div class="container text-start" v-else>
            <div class="row">
                <div class="col">
                    <form @submit.prevent.stop="Save">
                        <fieldset :disabled="disabled">
                            <div class="form-group">
                                <label>User Name</label>
                                <input autocomplete="off" autosuggest="off" class="text-uppercase form-control" name="user[User Code]" v-model="user['User Code']" maxlength="15" @keyup="user['User Code'] = user['User Code'].toUpperCase()">
                            </div>
                            <div class="form-group">
                                <label>Description</label>
                                <input autocomplete="off" autosuggest="off" class=" form-control" name="user[Name]" v-model="user['Name']">
                            </div>
                            <div class="form-group">
                                <label>Email Address</label>
                                <input autocomplete="off" autosuggest="off" class=" form-control" name="user[Email]" v-model="user['Email']">
                            </div>
                            <div class="form-group">
                                <label>Password</label>
                                <input autocomplete="off" autosuggest="off" type="password" class=" form-control" name="user[Password]" v-model="user['Password']">
                            </div>
                            <div class="form-group">
                                <label>Mapped Id</label>
                                <input autocomplete="off" autosuggest="off" class=" form-control" name="user[Mapped Route No]" @keyup="user['Mapped Route No'] = user['Mapped Route No'].toUpperCase()" v-model="user['Mapped Route No']">
                            </div>
                            <div class="form-group">
                                <label>Expires On</label>
                                <input autocomplete="off" autosuggest="off" class="datepicker form-control" name="user[Expires On]" v-model="user['Expires On']">
                            </div>
                            <div class="form-group">
                                <label>Last On</label>
                                <input autocomplete="off" autosuggest="off" class=" form-control" readonly v-model="user['Last Logon']">
                            </div>
                            <div class="form-group">
                                <label>Created</label>
                                <input autocomplete="off" autosuggest="off" class=" form-control" readonly v-model="user['Created On']">
                            </div>
                            <div class="form-group">
                                <label>Logons</label>
                                <input autocomplete="off" autosuggest="off" class=" form-control"  v-model="user['Logon Count']" readonly>
                            </div>
                            <div class="form-group" data-title="Enable Integration">
                                <label>Mobile View:</label>
                                <div class="form-check form-switch">
                                    <input autocomplete="off" autosuggest="off" class="form-check-input" name="user[Responsive View]" :true-value="1" :false-value="0" type="checkbox" v-model="user['Responsive View']">
                                </div>    
                            </div>
                            <div class="form-group">
                                <label>Enable Integration:</label>
                                <div class="form-check form-switch">
                                    <input autocomplete="off" autosuggest="off" class="form-check-input" name="user[APIIntegrationPermission]" :true-value="1" :false-value="0" type="checkbox" v-model="user['APIIntegrationPermission']">
                                </div>    
                            </div>
                            <div class="form-group">
                                <label>Active:</label>
                                <div class="form-check form-switch">
                                    <input autocomplete="off" autosuggest="off" class="form-check-input" name="user[Status]" :true-value="1" :false-value="2" type="checkbox" v-model="user['Status']">
                                </div>    
                            </div>
                            <h5>Permissions</h5>
                            <hr>
                            <div class="form-group">
                                <label>Admin user</label>
                                <div class="form-check form-switch">
                                    <input autocomplete="off" autosuggest="off" class="form-check-input" name="user[]"  true-value="1" false-value="0" type="checkbox" v-model="user['Admin']" @change="toggleAllPermissions(user['Admin'])">
                                </div>    
                            </div>
                            <div class="form-group">
                                <label>Control Ord. Item List</label>
                                <div class="form-check form-switch">
                                    <input autocomplete="off" autosuggest="off" class="form-check-input" name="user[]" type="checkbox" true-value="1" false-value="0"  v-model="user['Allow Menu Change']">
                                </div>    
                            </div>
                            <div class="form-group">
                                <label>Daily Menu Maint</label>
                                <div class="form-check form-switch">
                                    <input autocomplete="off" autosuggest="off" class="form-check-input" name="user[]" type="checkbox" true-value="1" false-value="0" v-model="user['Menu Change']">
                                </div>    
                            </div>
                            <div class="form-group">
                                <label>Edit Users</label>
                                <div class="form-check form-switch">
                                    <input autocomplete="off" autosuggest="off" class="form-check-input" name="user[]" type="checkbox" true-value="1" false-value="0" v-model="user['Edit Users']">
                                </div>    
                            </div>
                            <div class="form-group">
                                <label>Item Maint</label>
                                <div class="form-check form-switch">
                                    <input autocomplete="off" autosuggest="off" class="form-check-input" name="user[]" type="checkbox" true-value="1" false-value="0" v-model="user['item List Change']">
                                </div>    
                            </div>
                            <div class="form-group">
                                <label>Location Maint.</label>
                                <div class="form-check form-switch">
                                    <input autocomplete="off" autosuggest="off" class="form-check-input" name="user[]" type="checkbox" true-value="1" false-value="0"   v-model="user['Location Maint']">
                                </div>    
                            </div>
                            <div class="form-group">
                                <label>Reports (Invoice)</label>
                                <div class="form-check form-switch">
                                    <input autocomplete="off" autosuggest="off" class="form-check-input" name="user[]" type="checkbox"    true-value="1" false-value="0" v-model="user['Invoice Report']">
                                </div>    
                            </div>
                            <div class="form-group">
                                <label>Reports (Other)</label>
                                <div class="form-check form-switch">
                                    <input autocomplete="off" autosuggest="off" class="form-check-input" name="user[]" type="checkbox"   true-value="1" false-value="0" v-model="user['Reports']">
                                </div>    
                            </div>
                            <div class="form-group">
                                <label>Waste Functions</label>
                                <div class="form-check form-switch">
                                    <input autocomplete="off" autosuggest="off" class="form-check-input" name="user[]" type="checkbox"  true-value="1" false-value="0" v-model="user['Waste System']">
                                </div>    
                            </div>
                            <div class="form-group" data-title="Pay Bill" >
                                <label>Pay Bill</label>
                                <div class="form-check form-switch">
                                    <input autocomplete="off" autosuggest="off" class="form-check-input" name="user[]" type="checkbox"  true-value="1" false-value="0" v-model="user['pay_bill']">
                                </div>    
                            </div>
                            <div class="form-group" data-title="View MSRP" >
                                <label>View MSRP</label>
                                <div class="form-check form-switch">
                                    <input autocomplete="off" autosuggest="off" class="form-check-input" name="user[]" type="checkbox"  true-value="1" false-value="0" v-model="user['View_MSRP']">
                                </div>    
                            </div>
                            <div class="form-group">
                                <button class="btn btn-primary me-1" type="submit">Create</button>
                                <a href="/users" class="btn btn-secondary">Back</a>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    </PageContent>
  </template>
  
  <script>
  import PageContent from '@/components/PageContent.vue';
    import NotReady from '@/components/NotReady.vue';
    import PageTitle from '@/components/PageTitle.vue';
    import Table from '@/components/Table/Table.vue';
    import TableRow from '@/components/Table/TableRow.vue';
    import TableHead from '@/components/Table/TableHead.vue';
    import TableCell from '@/components/Table/TableCell.vue';
import axios from 'axios';

  export default {
    name: 'UsersCreate',
    components: {PageContent, PageTitle, Table, TableRow, TableCell, NotReady, TableHead},
    data(){
        return {
            ready:false,
            disabled:false,
            user:{},
            success:'',
            error:'',
            columns:[],
        }
    },
    async mounted(){
        let res = await axios.get(`${this.$store.state.api}/user_columns?token=${localStorage.token}`);

        this.columns = res;
        this.columns.forEach(column=>{
            this.user[column] = '';
        });
        this.user['Email'] = '';
        this.user['Customer No'] = '';
        this.user['Expires On'] = '';
        // this.user['Password'] = '';
        this.user['Created On'] = this.$root.moment().format('MM/DD/YYYY');
        this.user['Logon Count'] = '0';
        this.user['Division'] = 'WH';
        // this.user['Last Logon'] = '12/31/2099';
        this.user['Deleted'] = '0';

        this.user['ItmLst_Vis_Authorized'] = 0;
        this.user['ItmLst_Vis_BaseCost'] = 0;
        this.user['ItmLst_Vis_Description'] = 1;
        this.user['ItmLst_Vis_GlutenFree'] = 0;
        this.user['ItmLst_Vis_Healthy'] = 0;
        this.user['ItmLst_Vis_Image'] = 0;
        this.user['ItmLst_Vis_ItemNo'] = 1;
        this.user['ItmLst_Vis_Lenten'] = 0;
        this.user['ItmLst_Vis_MSRP'] = 0;
        this.user['ItmLst_Vis_PriceCode'] = 1;
        this.user['ItmLst_Vis_Restricted'] = 0;
        this.user['ItmLst_Vis_UpcCode'] = 1;
        this.user['ItmLst_Vis_UserDef'] = 1;
        this.user['ItmLst_Vis_Vegan'] = 0;
        this.user['ItmLst_Vis_Vegetarian'] = 0;
        this.user['ItmMaint Scroll'] = 0;
        this.user['Order Entry Scroll Items'] = 1;
        this.user['Order Entry Group Items'] = 1;
        this.user['Order_Vis_Authorized'] = 0;
        this.user['Order_Vis_BaseCost'] = 0;
        this.user['Order_Vis_Description'] = 1;
        this.user['Order_Vis_Extended'] = 0;
        this.user['Order_Vis_GlutenFree'] = 0;
        this.user['Order_Vis_Healthy'] = 0;
        this.user['Order_Vis_Hist1'] = 0;
        this.user['Order_Vis_Hist2'] = 0;
        this.user['Order_Vis_Hist3'] = 0;
        this.user['Order_Vis_Hist4'] = 0;
        this.user['Order_Vis_Hist5'] = 0;
        this.user['Order_Vis_Hist6'] = 0;
        this.user['Order_Vis_Hist7'] = 0;
        this.user['Order_Vis_Hist8'] = 0;
        this.user['Order_Vis_Hist9'] = 0;
        this.user['Order_Vis_Hist10'] = 0;
        this.user['Order_Vis_HistTot'] = 0;
        this.user['Order_Vis_Image'] = 0;
        this.user['Order_Vis_ItemNo'] = 1;
        this.user['Order_Vis_Lenten'] = 0;
        this.user['Order_Vis_MSRP'] = 0;
        this.user['Order_Vis_MSRPCode'] = 0;
        this.user['Order_Vis_PriceCode'] = 1;
        this.user['Order_Vis_PullCode'] = 0;
        this.user['Order_Vis_RemoveFromMenu'] = 0;
        this.user['Order_Vis_Restricted'] = 0;
        this.user['Order_Vis_TraySize'] = 0;
        this.user['Order_Vis_UpcCode'] = 0;
        this.user['Order_Vis_UserDef'] = 1;
        this.user['Order_Vis_Vegan'] = 0;
        this.user['Order_Vis_Vegetarian'] = 0;
        this.user['Deleted'] = 0;
        this.user['Status'] = 1;
        this.user['Location Days'] = 0;
        this.user['pay_bill'] = 0;
        this.user['Responsive View'] = 0;
        this.user['View_MSRP'] = 0;
        this.ready = true;
        setTimeout(()=>{
            $('.datepicker').datepicker({
                changeMonth: true,
                changeYear: true,
                // dateFormat:'mm/dd/yy',
                yearRange: "2000:2099",
                onSelect:(e)=>{
                    this.user['Expires On'] = e;
                }
            });
        },10);
    },
    methods:{
        async toggleAllPermissions(isAdmin){
            if(isAdmin=='1'){
                this.user['Allow Menu Change'] = '1';
                this.user['Menu Change'] = '1';
                this.user['Edit Users'] = '1';
                this.user['item List Change'] = '1';
                this.user['Location Maint'] = '1';
                this.user['Invoice Report'] = '1';
                this.user['Reports'] = '1';
                this.user['Waste System'] = '1';
                this.user['Menu Days'] = '1';
                this.user['View_MSRP'] = '1';

            }
            else{
                this.user['Allow Menu Change'] = '0';
                this.user['Menu Change'] = '0';
                this.user['Edit Users'] = '0';
                this.user['item List Change'] = '0';
                this.user['Location Maint'] = '0';
                this.user['Invoice Report'] = '0';
                this.user['Reports'] = '0';
                this.user['Waste System'] = '0';
                this.user['Menu Days'] = '0';
                this.user['View_MSRP'] = '0';

            }
        },
        async Save(){
            this.user['Order Entry History Display'] = 'Last';

            if(this.user['Expires On'].trim()==''){
                this.user['Expires On'] = '12/31/2099';
            }
            let formData = new FormData();
            formData.append('user', JSON.stringify(this.user));
            let res = await axios.put(`${this.$store.state.api}/user?`, formData);
            if(res.message == 'error'){

            }
            else{
                this.$router.replace(`/users/`);
            }
        },
    },
  }
  </script>
  