<template>
    <PageContent>
        <PageTitle>Edit: {{$route.params['user_code']}}</PageTitle>
        <NotReady v-if="!ready" />
        <div class="container text-start" v-else>
            <div class="row">
                <div class="col">
                    <form @submit.prevent.stop="Save">
                        <fieldset :disabled="disabled">
                            <div class="form-group" data-title="User Name">
                                <label>User Name</label>
                                <input autocomplete="off" autosuggest="off" class="text-uppercase form-control" name="user[User Code]" v-model="user['User Code']" maxlength="15" @keyup="user['User Code'] = user['User Code'].toUpperCase()" readonly />
                            </div>
                            <div class="form-group" data-title="Name">
                                <label>Name</label>
                                <input autocomplete="off" autosuggest="off" class=" form-control" name="user[Name]" v-model="user['Name']">
                            </div>
                            <div class="form-group" data-title="Email Address">
                                <label>Email Address</label>
                                <input autocomplete="off" autosuggest="off" class=" form-control" name="user[Email]" v-model="user['Email']">
                            </div>
                            <div class="form-group">
                                <label>Password</label>
                                <div class="input-group" data-title="View Password">
                                    <input autocomplete="off" autosuggest="off" :type="passwordField" class=" form-control" name="user[Password]" v-model="user['Password']">
                                    <span class="input-group-text" @mousedown.prevent="passwordField=='password'?passwordField='text':passwordField='password'" style="cursor:pointer;"><i class="fa-regular fa-eye" ></i></span>
                                </div>
                            </div>
                            <div class="form-group" data-title="Mapped ID">
                                <label>Mapped Id</label>
                                <input autocomplete="off" autosuggest="off" class=" form-control" name="user[Mapped Route No]" @keyup="user['Mapped Route No'] = user['Mapped Route No'].toUpperCase()" v-model="user['Mapped Route No']">
                            </div>
                            <div class="form-group" data-title="Expires On">
                                <label>Expires On</label>
                                <input autocomplete="off" autosuggest="off" class="datepicker form-control" name="user[Expires On]" v-model="user['Expires On']" @keydown.prevent>
                            </div>
                            <div class="form-group">
                                <label>Last On</label>
                                <input autocomplete="off" autosuggest="off" class=" form-control" readonly v-model="user['Last Logon']">
                            </div>
                            <div class="form-group">
                                <label>Created</label>
                                <input autocomplete="off" autosuggest="off" class=" form-control" readonly v-model="user['Created On']">
                            </div>
                            <div class="form-group">
                                <label>Logons</label>
                                <input autocomplete="off" autosuggest="off" class=" form-control"  v-model="user['Logon Count']" readonly>
                            </div>


                            


                            <div class="form-group" data-title="Enable Integration">
                                <label>Mobile View:</label>
                                <div class="form-check form-switch">
                                    <input autocomplete="off" autosuggest="off" class="form-check-input" name="user[Responsive View]" :true-value="1" :false-value="0" type="checkbox" v-model="user['Responsive View']">
                                </div>    
                            </div>
                            <div class="form-group" data-title="Enable Integration">
                                <label>Enable Integration:</label>
                                <div class="form-check form-switch">
                                    <input autocomplete="off" autosuggest="off" class="form-check-input" name="user[APIIntegrationPermission]" :true-value="1" :false-value="0" type="checkbox" v-model="user['APIIntegrationPermission']">
                                </div>    
                            </div>
                            <div class="form-group" data-title="Active">
                                <label>Active:</label>
                                <div class="form-check form-switch">
                                    <input autocomplete="off" autosuggest="off" class="form-check-input" name="user[Status]" :true-value="1" :false-value="2" type="checkbox" v-model="user['Status']">
                                </div>    
                            </div>
                            <h5>Permissions</h5>
                            <hr>
                            <div class="form-group" data-title="Admin User">
                                <label>Admin user</label>
                                <div class="form-check form-switch">
                                    <input autocomplete="off" autosuggest="off" class="form-check-input" name="user[]"  true-value="1" false-value="0" type="checkbox" v-model="user['Admin']" @change="toggleAllPermissions(user['Admin'])">
                                </div>    
                            </div>
                            <div class="form-group" data-title="Control Ord. Item List">
                                <label>Control Ord. Item List</label>
                                <div class="form-check form-switch">
                                    <input autocomplete="off" autosuggest="off" class="form-check-input" name="user[]" type="checkbox" true-value="1" false-value="0"  v-model="user['Allow Menu Change']">
                                </div>    
                            </div>
                            <div class="form-group" data-title="Daily Menu Maint">
                                <label>Daily Menu Maint</label>
                                <div class="form-check form-switch">
                                    <input autocomplete="off" autosuggest="off" class="form-check-input" name="user[]" type="checkbox" true-value="1" false-value="0" v-model="user['Menu Change']">
                                </div>    
                            </div>
                            <div class="form-group" data-title="Edit Users">
                                <label>Edit Users</label>
                                <div class="form-check form-switch">
                                    <input autocomplete="off" autosuggest="off" class="form-check-input" name="user[]" type="checkbox" true-value="1" false-value="0" v-model="user['Edit Users']">
                                </div>    
                            </div>
                            <div class="form-group" data-title="Item Maint">
                                <label>Item Maint</label>
                                <div class="form-check form-switch">
                                    <input autocomplete="off" autosuggest="off" class="form-check-input" name="user[]" type="checkbox" true-value="1" false-value="0" v-model="user['item List Change']">
                                </div>    
                            </div>
                            <div class="form-group" data-title="Location Maint.">
                                <label>Location Maint.</label>
                                <div class="form-check form-switch">
                                    <input autocomplete="off" autosuggest="off" class="form-check-input" name="user[]" type="checkbox" true-value="1" false-value="0"   v-model="user['Location Maint']">
                                </div>    
                            </div>
                            <div class="form-group" data-title="Reports (Invoice)">
                                <label>Reports (Invoice)</label>
                                <div class="form-check form-switch">
                                    <input autocomplete="off" autosuggest="off" class="form-check-input" name="user[]" type="checkbox"    true-value="1" false-value="0" v-model="user['Invoice Report']">
                                </div>    
                            </div>
                            <div class="form-group" data-title="Reports (Other)" >
                                <label>Reports (Other)</label>
                                <div class="form-check form-switch">
                                    <input autocomplete="off" autosuggest="off" class="form-check-input" name="user[]" type="checkbox"   true-value="1" false-value="0" v-model="user['Reports']">
                                </div>    
                            </div>
                            <div class="form-group" data-title="Waste Functions" >
                                <label>Waste Functions</label>
                                <div class="form-check form-switch">
                                    <input autocomplete="off" autosuggest="off" class="form-check-input" name="user[]" type="checkbox"  true-value="1" false-value="0" v-model="user['Waste System']">
                                </div>    
                            </div>
                            <div class="form-group" data-title="Pay Bill" >
                                <label>Pay Bill</label>
                                <div class="form-check form-switch">
                                    <input autocomplete="off" autosuggest="off" class="form-check-input" name="user[]" type="checkbox"  true-value="1" false-value="0" v-model="user['pay_bill']">
                                </div>    
                            </div>
                            <div class="form-group" data-title="View MSRP" >
                                <label>View MSRP</label>
                                <div class="form-check form-switch">
                                    <input autocomplete="off" autosuggest="off" class="form-check-input" name="user[]" type="checkbox"  true-value="1" false-value="0" v-model="user['View_MSRP']">
                                </div>    
                            </div>
                            
                            <div class="form-group">
                                <button class="btn btn-primary me-1" type="submit">Update</button>
                                <a href="/users" class="btn btn-secondary">Back</a>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    </PageContent>
  </template>
  
  <script>
    import PageContent from '@/components/PageContent.vue';
    import NotReady from '@/components/NotReady.vue';
    import PageTitle from '@/components/PageTitle.vue';
    import TableHead from '@/components/Table/TableHead.vue';
    import Help from '@/components/Help.vue';
import axios from 'axios';

  export default {
    name: 'UsersEdit',
    components: {PageContent, PageTitle, NotReady, TableHead, Help},
    data(){
        return {
            ready:false,
            disabled:false,
            user:{},
            oldUser:{},
            passwordField:'password'
        }
    },
    async mounted(){
        let res = await axios.get(`${this.$store.state.api}/user?lccustno=${this.$root.getCustomerValue('Customer No_')}&user_code=${this.$route.params['name']}`)
        this.user = res;
        this.oldUser = {...res};
        this.user['Expires On'] = this.$root.moment(this.user['Expires On'].date).format('MM/DD/YYYY');
        this.user['Last Logon'] = this.$root.moment(this.user['Last Logon'].date).format('MM/DD/YYYY');
        this.user['Created On'] = this.$root.moment(this.user['Created On'].date).format('MM/DD/YYYY');
        this.user['Deleted'] = '0';
        if(this.user.Status  == 1 || this.user.Status == 0) this.user.Status = 1;
        this.ready = true;
        setTimeout(()=>{
            $('.datepicker').datepicker({
                changeMonth: true,
                changeYear: true,
                yearRange: "2000:2099",
                onSelect:(e)=>{
                    this.user['Expires On'] = e;
                }
            });
        },10);
    },
    methods:{
        async toggleAllPermissions(isAdmin){
            if(isAdmin=='1'){
                this.user['Allow Menu Change'] = '1';
                this.user['Menu Change'] = '1';
                this.user['Edit Users'] = '1';
                this.user['item List Change'] = '1';
                this.user['Location Maint'] = '1';
                this.user['Invoice Report'] = '1';
                this.user['Reports'] = '1';
                this.user['Waste System'] = '1';
                this.user['Menu Days'] = '1';
                this.user['pay_bill'] = '1';
                this.user['View_MSRP'] = '1';

            }
            else{
                this.user['Allow Menu Change'] = '0';
                this.user['Menu Change'] = '0';
                this.user['Edit Users'] = '0';
                this.user['item List Change'] = '0';
                this.user['Location Maint'] = '0';
                this.user['Invoice Report'] = '0';
                this.user['Reports'] = '0';
                this.user['Waste System'] = '0';
                this.user['Menu Days'] = '0';
                this.user['pay_bill'] = '0';
                this.user['View_MSRP'] = '0';

            }
        },
        async Save(){
            if(this.user['Expires On'].trim()==''){
                this.user['Expires On'] = '12/31/2099';
            }
            let user = {...this.user};
            if(user.Status!=2) user.Status = this.oldUser.Status;
            this.disabled=true;
            let req, res;
            let formData = new FormData();
            formData.append('user', JSON.stringify(user));
            res = await axios.post(`${this.$store.state.api}/user?`, formData);
            this.disabled = false;
            
            if(this.user['User Code']==this.$store.state.localStorage.user['User Code']){
                localStorage.setItem('user', JSON.stringify(res.user));
                this.$store.state.localStorage.user = res.user;
            }
        },
    },
  }
  </script>
  